import React from "react";
import { attachScript, detachScript } from "../../components/TIQScript";
// @ts-ignore
import PuzzleBoard from "./Puzzle";
import { Helmet } from "react-helmet";
import useInterval from "../../components/TimerHooks";
import { useHistory } from "react-router";

export const Puzzle = () => {
  const history = useHistory();
  const [finished, setFinished] = React.useState(false);
  const [timer, setTimer] = React.useState(60);
  const [timeout, setTimeout] = React.useState(false);
  const [started, setStarted] = React.useState(false);

  useInterval(() => {
    if (timer > 0 && started) setTimer(timer - 1);
  }, 1000);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: "Puzzle",
      page_title: "Lexus Lexperience | Games",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (timer <= 0) {
      setTimeout(true);
      setStarted(false);
    }
  }, [timer]);

  return (
    <div className="flex flex-col justify-center items-center">
      <Helmet>
        <title>Lexus Lexperience | Games</title>
      </Helmet>
      <div className="heading text-center tracking-widest">P U Z Z L E</div>
      <div className={`pt-8 `}>
        {!timeout ? (
          <div className="w-full relative ">
            <div className="heading text-center mb-4">
              Drag the pieces around to complete the puzzle!
            </div>
            <div className="relative">
              {!started ? <div className="absolute inset-0" /> : null}
              <PuzzleBoard
                image={process.env.PUBLIC_URL + "/images/puzzle.png"}
                width={360}
                height={360}
                pieces={4}
                onComplete={() => {
                  setFinished(true);
                }}
              />
            </div>
            {finished ? (
              <div className="flex flex-col justify-center items-center">
                <div className="heading text-center text-2xl mt-4">
                  PERFECT! YOU'RE DOING GREAT
                </div>
                <button
                  className={`heading bg-gray-600  text-white w-40 py-1 rounded mt-4 self-center`}
                  onClick={() => {
                    history.replace("/games");
                  }}
                >
                  Back
                </button>
              </div>
            ) : started ? (
              <div>
                <div className="heading text-center mt-6">Time Left:</div>
                <div className="heading text-center text-xl">{timer}</div>
              </div>
            ) : (
              <div className="items-center justify-center flex">
                <button
                  className={`heading bg-gray-600  text-white w-40 py-1 rounded mt-4`}
                  onClick={() => {
                    setStarted(true);
                  }}
                >
                  Start
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="items-center justify-center flex flex-col heading">
            <div>Time Out!</div>

            <button
              className={`heading bg-gray-600  text-white w-40 py-1 rounded mt-4`}
              onClick={() => {
                setTimeout(false);
                setFinished(false);
                setTimer(60);
              }}
            >
              Try Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Puzzle;
