import axios from "axios";
import { postFormRequest, util } from "./types";
import { getTimeApi, postFormApi, utilApi } from "./urls";

const postForm = async (req: postFormRequest) => {
  try {
    const response = await axios.post(postFormApi, req);

    return response;
  } catch {
    throw new Error("Unknown Error");
  }
};

const getUtils = async () => {
  try {
    const response: { data: util[] } = await axios.get(utilApi);

    return response.data;
  } catch {
    throw new Error("Unknown Error");
  }
};

const getTime = async () => {
  try {
    const response: { data: { time: number } } = await axios.get(getTimeApi);

    return response.data.time;
  } catch {
    throw new Error("Unknown Error");
  }
};

export const API = {
  postForm,
  getUtils,
  getTime,
};
