import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { attachScript, detachScript } from "../components/TIQScript";

export const GamesMenu = () => {
  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: "Menu",
      page_title: "Lexus Lexperience | Games",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mb-12 flex flex-col justify-center items-center">
      <Helmet>
        <title>Lexus Lexperience | Games</title>
      </Helmet>
      <div
        className={`pt-8 md:pt-20 grid md:flex md:justify-center md:items-center grid-cols-2 gap-4 md:gap-8 heading`}
      >
        <div className="flex justify-center items-center">
          <Link
            to={`/games/puzzle`}
            className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
          >
            <div className="md:text-lg text-center ">PUZZLE</div>
          </Link>
        </div>

        <div className="flex justify-center items-center">
          <Link
            to={`/games/difference`}
            className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
          >
            <div className="md:text-lg text-center ">SPOT THE DIFFERENCE</div>
          </Link>
        </div>
        <div className="flex justify-center items-center">
          <Link
            to={`/games/words`}
            className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
          >
            <div className="md:text-lg text-center ">SEARCH WORDS</div>
          </Link>
        </div>
        <div className="flex justify-center items-center ">
          <Link
            to={`/games/trivia`}
            className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
          >
            <div className="md:text-lg text-center ">TRIVIA</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GamesMenu;
