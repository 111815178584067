import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { attachScript, detachScript } from "../components/TIQScript";

export const Home = () => {
  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: "Home",
      page_title: "Lexus Lexperience",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col justify-center items-center heading pt-20 pb-20 ">
      <Helmet>
        <title>Lexus Lexperience | Home</title>
      </Helmet>
      <div>
        <div className="flex justify-center items-center h-24 md:h-40 2xl:h-56">
          <Link
            to="/cars/sedan"
            className="flex flex-col justify-center items-center w-24 2xl:w-48 md:w-40 2xl:h-56 h-full"
          >
            <div className="text-3xl md:text-4xl 2xl:text-5xl text-center">
              SEDAN
            </div>
          </Link>
          <div className="w-px bg-gray-400 h-full mx-8" />
          <Link
            to="/cars/suv"
            className="flex flex-col justify-center items-center w-24 2xl:w-48 md:w-40 2xl:h-56 h-full"
          >
            <div className="text-3xl md:text-4xl 2xl:text-5xl text-center">
              SUV
            </div>
          </Link>
        </div>

        <div className="h-px bg-gray-400 w-full my-6" />

        <div className="flex justify-center items-center h-24 md:h-40 2xl:h-56">
          <Link
            to="/games"
            className="flex flex-col justify-center items-center w-24 2xl:w-48 md:w-40 2xl:h-56 h-full"
          >
            <div className="text-3xl md:text-4xl 2xl:text-5xl text-center">
              TRIVIA & GAMES
            </div>
          </Link>
          <div className="w-px bg-gray-400 h-full mx-8 " />
          <a
            href="https://www.lexus.com.bn/en/promotions.html"
            target="_blank"
            rel="noreferrer"
            className="flex-1 "
          >
            <div className="flex flex-col justify-center items-center w-24 2xl:w-48 md:w-40 2xl:h-56 h-full">
              <div className="text-3xl md:text-4xl 2xl:text-5xl text-center">
                PROMO
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
