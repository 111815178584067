import React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { images } from "../assets/images";

export const Navbar = () => {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      (document.querySelector("#navbar") as HTMLInputElement).className =
        "bg-white shadow sticky top-0 z-40 lg:z-50 w-full transition";
    } else {
      (document.querySelector("#navbar") as HTMLInputElement).className =
        "sticky top-0 z-40 lg:z-50 w-full transition";
    }
  };

  const onBack = () => {
    if (
      location.pathname.includes("/puzzle") ||
      location.pathname.includes("/difference") ||
      location.pathname.includes("/words") ||
      location.pathname.includes("/trivia") ||
      location.pathname.includes("/form")
    ) {
      history.replace(`/games`);
    } else if (location.pathname.includes("/cars/sedan/es")) {
      history.replace("/cars/sedan");
    } else if (
      location.pathname.includes("/cars/sedan") ||
      location.pathname.includes("/cars/suv") ||
      location.pathname.includes("/games")
    ) {
      history.replace("/home");
    } else if (
      location.pathname.includes("/is/menu") ||
      location.pathname.includes("/es/menu")
    ) {
      history.replace(`/cars/sedan`);
    } else if (
      location.pathname.includes("/nx/menu") ||
      location.pathname.includes("/rx/menu") ||
      location.pathname.includes("/ux/menu") ||
      location.pathname.includes("/lx/menu")
    ) {
      history.replace(`/cars/suv`);
    } else if (
      location.pathname.includes("/es-f-sport/menu") ||
      location.pathname.includes("/es-300h/menu") ||
      location.pathname.includes("/es-250/menu")
    ) {
      history.replace(`/cars/sedan/es`);
    } else if (location.pathname.includes("/exterior/zoom")) {
      history.replace(`/detail/${location.pathname.split("/")[2]}/exterior`);
    } else if (location.pathname.includes("/interior/zoom")) {
      history.replace(`/detail/${location.pathname.split("/")[2]}/interior`);
    } else if (
      location.pathname.includes("/exterior") ||
      location.pathname.includes("/interior") ||
      location.pathname.includes("/video")
    ) {
      history.replace(`/detail/${location.pathname.split("/")[2]}/menu`);
    }
  };

  return (
    <div id="navbar" className="sticky top-0 z-40 lg:z-50 w-full">
      <div className=" max-w-8xl mx-auto flex-none flex items-center justify-center p-4 max-w-screen-lg xl:max-w-screen-xl relative">
        {!location.pathname.includes("home") && (
          <div onClick={onBack} className="p-4 absolute left-0 cursor-pointer">
            <i className="fas fa-chevron-left text-xl" />
          </div>
        )}
        <Link to="/home">
          <img src={images.lexus} alt="lexus_logo" className="h-14" />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
