import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { images } from "../assets/images";
import { attachScript, detachScript } from "../components/TIQScript";

export const Difference = () => {
  const history = useHistory();
  const questions: {
    image: any;
    answerImage: any;
    option: string[];
    answer: "a" | "b" | "c" | "d";
  }[] = [
    {
      image: images.diff1,
      answerImage: images.diffAnswer1,
      option: ["A. Tyres", "B. Logo", "C. Front Garnish", "D. Rims"],
      answer: "d",
    },
    {
      image: images.diff2,
      answerImage: images.diffAnswer2,
      option: ["A. Tyres", "B. Logo", "C. Front Garnish", "D. Rims"],
      answer: "c",
    },
    {
      image: images.diff3,
      answerImage: images.diffAnswer3,
      option: ["A. Tyres", "B. Headlight", "C. Front Garnish", "D. Rims"],
      answer: "b",
    },
    {
      image: images.diff4,
      answerImage: images.diffAnswer4,
      option: [
        "A. Analog Clock",
        "B. Push Button",
        "C. Screen",
        "D. Dashboard",
      ],
      answer: "a",
    },
  ];
  const [showModal, setShowModal] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [answers, setAnswers] = React.useState<("a" | "b" | "c" | "d")[]>([]);
  const [currentAnswer, setCurrentAnswer] = React.useState<
    "a" | "b" | "c" | "d"
  >();
  const [score, setScore] = React.useState(0);

  const [finished, setFinished] = React.useState(false);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: "Find the Difference",
      page_title: "Lexus Lexperience | Games",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (currentAnswer) {
      const _answers = [...answers, currentAnswer];
      setAnswers(_answers);
      if (currentQuestion + 1 < questions.length) {
        setCurrentAnswer(undefined);
        setCurrentQuestion(currentQuestion + 1);
      } else {
        let _score = 0;
        questions.forEach((v, i) => {
          if (v.answer === _answers[i]) {
            _score += 1;
          }
        });
        setScore(_score);
        setFinished(true);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Lexus Lexperience | Find the Difference</title>
      </Helmet>
      <div className="heading text-center tracking-widest">
        S P O T&nbsp;&nbsp;&nbsp;T H E&nbsp;&nbsp;&nbsp;D I F F E R E N C E
      </div>{" "}
      <div className="heading text-center tracking-widest">
        Question {currentQuestion + 1}/{questions.length}
      </div>
      <div className={`pt-8 md:pt-12 px-4 mb-12`}>
        <div className="w-full relative ">
          {finished ? (
            <div className="items-center justify-center flex flex-col heading">
              <div>Point</div>
              <div className="text-2xl">
                {score}/{questions.length}
              </div>
              <button
                className={`heading ${
                  currentAnswer ? "bg-gray-600" : "bg-gray-200 cursor-default"
                }  text-white w-40 py-1 rounded mt-4`}
                onClick={() => {
                  setAnswers([]);
                  setScore(0);
                  setFinished(false);
                  setCurrentQuestion(0);
                  setCurrentAnswer(undefined);
                }}
              >
                Try Again
              </button>
              <button
                className={`heading bg-gray-600  text-white w-40 py-1 rounded mt-4`}
                onClick={() => {
                  history.replace("/games");
                }}
              >
                Back
              </button>

              {/* Summary */}
              <div className="mt-8">
                <div className="heading text-xl text-center mb-12">Summary</div>
                {questions.map((v, i) => {
                  return (
                    <div key={`question-${i}`}>
                      <div className="py-4">
                        <div className="heading tracking-widest">
                          <span className="font-bold">{i + 1}. </span>

                          {answers[i] === v.answer ? (
                            <span className="text-green-600 font-bold">
                              Correct
                            </span>
                          ) : (
                            <span className="text-red-600 font-bold">
                              Wrong
                            </span>
                          )}
                        </div>

                        {v.answerImage && (
                          <img
                            src={v.answerImage}
                            alt="car"
                            className="w-full object-contain mt-2"
                          />
                        )}

                        <div className="w-80 mt-4 mb-4">
                          {v.option.map((_v) => (
                            <div key={_v} className="heading ">
                              {_v}
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="heading">
                            Your answer:{" "}
                            <span className="font-bold">
                              {answers[i].toUpperCase()}
                            </span>
                          </div>
                          <div className="heading">
                            Correct answer:{" "}
                            <span className="font-bold">
                              {v.answer.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="h-px bg-gray-400 w-full my-4" />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="items-center justify-center flex flex-col">
              <img
                src={questions[currentQuestion].image}
                alt="car"
                className="h-full w-full object-cover"
              />
              <div className="flex space-x-16 justify-center items-center mt-8">
                <div className="space-y-4">
                  <div
                    key={questions[currentQuestion].option[0]}
                    className="heading tracking-widest text-left "
                  >
                    {questions[currentQuestion].option[0]}
                  </div>
                  <div
                    key={questions[currentQuestion].option[2]}
                    className="heading tracking-widest text-left "
                  >
                    {questions[currentQuestion].option[2]}
                  </div>
                </div>
                <div className=" space-y-4">
                  <div
                    key={questions[currentQuestion].option[1]}
                    className="heading tracking-widest text-left "
                  >
                    {questions[currentQuestion].option[1]}
                  </div>
                  <div
                    key={questions[currentQuestion].option[3]}
                    className="heading tracking-widest text-left "
                  >
                    {questions[currentQuestion].option[3]}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-12 mt-12 heading">
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="a"
                    checked={currentAnswer === "a"}
                    onChange={(e) => {
                      setCurrentAnswer("a");
                    }}
                  />
                  <span className="">A</span>
                </label>
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="b"
                    checked={currentAnswer === "b"}
                    onChange={(e) => {
                      setCurrentAnswer("b");
                    }}
                  />
                  <span className="">B</span>
                </label>
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="c"
                    checked={currentAnswer === "c"}
                    onChange={(e) => {
                      setCurrentAnswer("c");
                    }}
                  />
                  <span className="">C</span>
                </label>
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="d"
                    checked={currentAnswer === "d"}
                    onChange={(e) => {
                      setCurrentAnswer("d");
                    }}
                  />
                  <span className="">D</span>
                </label>
              </div>
              <button
                className={`heading ${
                  currentAnswer ? "bg-gray-600" : "bg-gray-200 cursor-default"
                }  text-white px-4 py-2 rounded mt-12`}
                disabled={!currentAnswer}
                onClick={onSubmit}
              >
                SUBMIT
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Difference;
