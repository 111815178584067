import React from "react";
import { Link, useParams } from "react-router-dom";
import { cars } from "../data/cars";
import ProgressiveImage from "react-progressive-graceful-image";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { attachScript, detachScript } from "../components/TIQScript";
import { Helmet } from "react-helmet";

export const Exterior = () => {
  const { id }: { id: string } = useParams();
  const car = cars.find((v) => v.id === id);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: car?.name ?? "",
      page_title: "Lexus Lexperience | Exterior",
      product_name: `Lexus ${car?.name ?? ""}`,
      product_price: "",
      product_series: car?.name ?? "",
      product_type: car?.type ?? "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <Helmet>
        <title>Lexus Lexperience | {car?.name} Exterior</title>
      </Helmet>
      <div className="heading text-center tracking-widest">E X T E R I O R</div>
      <div className={`pt-8 md:pt-12 w-full `}>
        <div
          className={`grid grid-cols-2 ${
            car?.exteriorImages && car?.exteriorImages?.length > 3
              ? "md:grid-cols-3 md:grid-rows-2"
              : car?.exteriorImages && car?.exteriorImages?.length >= 3
              ? "md:grid-cols-3"
              : "md:grid-cols-2"
          }  w-full relative`}
        >
          <div className="absolute z-10 bg-black bg-opacity-50 bottom-0 inset-x-0 py-2 px-2 overflow-hidden">
            <div className="heading text-center text-white tracking-widest font-medium">
              TAP ANY OF THE IMAGES ABOVE FOR MORE INFORMATION.
            </div>
          </div>
          {car?.exteriorImages?.map((v, i) => (
            <Link
              key={`image-${i}`}
              to={`/detail/${id}/exterior/zoom/${i}`}
              className={`${
                i === 0
                  ? `col-span-full ${
                      car?.exteriorImages?.length > 3
                        ? "md:col-auto md:row-span-full"
                        : "md:col-auto"
                    } `
                  : "w-full h-full"
              } overflow-hidden`}
            >
              <ProgressiveImage src={v.img} placeholder={v.imgsmall}>
                {(src: any, loading: boolean) => (
                  <div className="h-full w-full relative">
                    {loading && (
                      <div className="absolute z-10 inset-0 bg-black bg-opacity-20 flex justify-center items-center">
                        <Lottie animationData={loadingAnimation} />
                      </div>
                    )}
                    <img
                      src={src}
                      alt={car.name}
                      className="h-full w-full object-cover transform transition ease-in hover:scale-110 cursor-pointer"
                    />
                  </div>
                )}
              </ProgressiveImage>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Exterior;
