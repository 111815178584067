import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { Link, useParams } from "react-router-dom";
import LeadButton from "../components/LeadButton";
import { cars } from "../data/cars";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { attachScript, detachScript } from "../components/TIQScript";
import { Helmet } from "react-helmet";

export const Interior = () => {
  const { id }: { id: string } = useParams();
  const car = cars.find((v) => v.id === id);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: car?.name ?? "",
      page_title: "Lexus Lexperience | Interior",
      product_name: `Lexus ${car?.name ?? ""}`,
      product_price: "",
      product_series: car?.name ?? "",
      product_type: car?.type ?? "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Helmet>
        <title>Lexus Lexperience | {car?.name} Interior</title>
      </Helmet>
      <div className="heading text-center tracking-widest">I N T E R I O R</div>
      <div className={`pt-8 md:pt-12 w-full flex items-center justify-center`}>
        <div className=" space-x-6 relative overflow-x-scroll scrollbar scrollbar-thin whitespace-nowrap overflow-y-hidden pb-2">
          {car?.interiorImages?.map((v, i) => (
            <Link
              key={`image-${i}`}
              to={`/detail/${id}/interior/zoom/${i}`}
              className={`object-cover `}
            >
              <div className="h-96 w-40 overflow-hidden inline-block ">
                <ProgressiveImage src={v.img} placeholder={v.imgsmall}>
                  {(src: any, loading: boolean) => (
                    <div className="h-full w-full relative">
                      {loading && (
                        <div className="absolute z-10 inset-0 bg-black bg-opacity-20 flex justify-center items-center">
                          <Lottie animationData={loadingAnimation} />
                        </div>
                      )}
                      <img
                        src={src}
                        alt={car?.name}
                        className="h-96 w-40 object-cover transform transition ease-in hover:scale-110 cursor-pointer inline-block"
                      />
                    </div>
                  )}
                </ProgressiveImage>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <LeadButton />
    </div>
  );
};

export default Interior;
