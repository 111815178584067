import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { attachScript, detachScript } from "../components/TIQScript";

export const WordSearch = () => {
  const history = useHistory();
  const letters = [
    [
      ["E", "E", "P", "K", "O", "U", "M", "A", "N", "E", "N", "T", "C"],
      ["I", "X", "W", "I", "A", "D", "F", "R", "J", "B", "G", "A", "R"],
      ["D", "H", "P", "D", "O", "U", "M", "S", "T", "K", "N", "K", "A"],
      ["M", "I", "E", "E", "Q", "N", "H", "E", "T", "E", "Y", "U", "F"],
      ["B", "L", "R", "B", "R", "A", "E", "F", "U", "M", "N", "M", "T"],
      ["R", "A", "F", "Y", "P", "I", "O", "E", "X", "E", "P", "I", "M"],
      ["K", "R", "O", "V", "R", "A", "E", "H", "R", "O", "J", "W", "A"],
      ["D", "A", "R", "X", "W", "O", "M", "N", "E", "I", "Z", "W", "N"],
      ["U", "T", "M", "F", "D", "E", "N", "A", "C", "S", "N", "F", "S"],
      ["A", "I", "A", "K", "N", "U", "M", "W", "Z", "E", "R", "G", "H"],
      ["Z", "N", "N", "L", "Z", "I", "W", "I", "N", "I", "D", "Q", "I"],
      ["J", "G", "C", "E", "H", "U", "M", "A", "N", "E", "N", "C", "P"],
      ["S", "B", "E", "O", "C", "E", "N", "T", "R", "E", "D", "G", "S"],
    ],
    [
      ["Y", "E", "S", "U", "P", "E", "R", "I", "O", "R", "F", "J", "P"],
      ["I", "E", "W", "Q", "U", "A", "L", "I", "T", "Y", "G", "A", "U"],
      ["D", "H", "T", "D", "E", "U", "M", "S", "T", "K", "N", "K", "A"],
      ["M", "I", "E", "P", "Q", "H", "H", "E", "T", "E", "Y", "U", "L"],
      ["B", "L", "R", "B", "H", "A", "W", "F", "U", "M", "N", "M", "J"],
      ["R", "I", "M", "A", "G", "I", "N", "A", "T", "I", "V", "E", "S"],
      ["T", "E", "C", "H", "N", "O", "L", "O", "G", "Y", "J", "W", "R"],
      ["D", "A", "R", "X", "W", "O", "W", "O", "E", "I", "Z", "W", "N"],
      ["U", "O", "M", "O", "T", "E", "N", "A", "S", "H", "I", "F", "S"],
      ["A", "I", "A", "K", "N", "U", "M", "W", "Z", "O", "R", "J", "H"],
      ["Z", "N", "N", "L", "Z", "I", "W", "I", "N", "I", "P", "Q", "K"],
      ["E", "R", "T", "Q", "D", "R", "S", "U", "O", "N", "N", "H", "P"],
      ["B", "R", "A", "V", "E", "D", "E", "S", "I", "G", "N", "G", "Y"],
    ],
  ];
  const [answers, setAnswers] = React.useState([
    [
      [
        { answer: "Experience", hint: "E_p_r___ce", answered: false },
        { answer: "Amazing", hint: "A___i_g", answered: false },
      ],
      [
        { answer: "Takumi", hint: "T___mi", answered: false },
        { answer: "Craftmanship", hint: "C____man___p", answered: false },
      ],
      [
        { answer: "Exhilarating", hint: "E_____rat___", answered: false },
        { answer: "Performance", hint: "P_____mance", answered: false },
      ],
      [{ answer: "Pioneering", hint: "Pion____ng", answered: false }],
      [
        { answer: "Human", hint: "H__an", answered: false },
        { answer: "centred", hint: "___tred", answered: false },
      ],
    ],
    [
      [
        { answer: "Superior", hint: "S____ior", answered: false },
        { answer: "Quality", hint: "Qua____", answered: false },
      ],
      [
        { answer: "Yet", hint: "Y_t", answered: false },
        { answer: "Philosophy", hint: "P____sophy", answered: false },
      ],
      [
        { answer: "Imaginative", hint: "Imag____ive", answered: false },
        { answer: "Technology", hint: "T____ology", answered: false },
      ],
      [
        { answer: "Brave", hint: "B___e", answered: false },
        { answer: "Design", hint: "D__ign", answered: false },
      ],
      [{ answer: "Omotenashi", hint: "O___enashi", answered: false }],
    ],
  ]);

  const [questionNo, setQuestionNo] = React.useState(0);
  const [questionFinished, setQuestionFinished] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [selectedLetters, setSelectedLetters] = React.useState<
    { x: number; y: number }[]
  >([]);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: "Word Search",
      page_title: "Lexus Lexperience | Games",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let noFinished = true;
    answers[questionNo].forEach((v) => {
      v.forEach((_v) => {
        if (_v.answered === false) {
          noFinished = false;
        }
      });
    });

    if (noFinished) {
      if (questionNo < answers.length - 1) {
        setQuestionFinished(true);
      } else {
        setFinished(true);
      }
    }
  }, [answers, questionNo]);

  React.useEffect(() => {
    let answered: { answer: string; answered: boolean } | undefined;
    answers[questionNo].some((v) => {
      answered = v.find((_v) => {
        return (
          _v.answer.toUpperCase() ===
          selectedLetters
            .map((__v) => letters[questionNo][__v.y][__v.x])
            .join("")
            .toUpperCase()
        );
      });

      if (answered) {
        return true;
      } else {
        return false;
      }
    });
    if (answered) {
      const newAnswers = [...answers];

      answers[questionNo].forEach((v, i) => {
        v.forEach((_v, _i) => {
          if (answered?.answer === _v.answer) {
            newAnswers[questionNo][i][_i].answered = true;
          }
        });
      });

      setAnswers(newAnswers);
      setSelectedLetters([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLetters]);

  const onClickLetter = ({ x, y }: { x: number; y: number }) => {
    if (selectedLetters.length > 0) {
      const prevLetter = selectedLetters[selectedLetters.length - 1];
      if (
        selectedLetters.length === 1 &&
        ((prevLetter.x === x && prevLetter.y + 1 === y) ||
          (prevLetter.y === y && prevLetter.x + 1 === x) ||
          (prevLetter.y + 1 === y && prevLetter.x + 1 === x))
      ) {
        setSelectedLetters([...selectedLetters, { x, y }]);
      } else if (selectedLetters.length > 1) {
        const prev2Letter = selectedLetters[selectedLetters.length - 2];

        if (
          (prev2Letter.x === x && prev2Letter.y + 2 === y) ||
          (prev2Letter.y === y && prev2Letter.x + 2 === x) ||
          (prev2Letter.y + 2 === y && prev2Letter.x + 2 === x)
        ) {
          setSelectedLetters([...selectedLetters, { x, y }]);
        }
      }
    } else {
      setSelectedLetters([{ x, y }]);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Lexus Lexperience | Word Search</title>
      </Helmet>
      <div className="heading text-center tracking-widest">
        S E A R C H&nbsp;&nbsp;&nbsp;W O R D S&nbsp;&nbsp;&nbsp;
      </div>
      <div
        className={`pt-8 md:pt-12 flex flex-col justify-center items-center`}
      >
        <div className="flex flex-col border-t border-l border-gray-600">
          {letters[questionNo].map((v, y) => (
            <div key={y} className="flex flex-1 flex-row">
              {v.map((_v, x) => {
                const isSelected = selectedLetters.find(
                  (v) => v.x === x && v.y === y
                );
                return (
                  <div
                    key={x}
                    onClick={() => {
                      onClickLetter({ x, y });
                    }}
                    className={`flex-1 text-sm md:text-base w-6 h-6 sm:w-7 sm:h-7 md:h-8 md:w-8 border-r border-b border-gray-600 flex flex-row justify-center items-center hover:bg-blue-100 transition cursor-pointer ${
                      isSelected ? "bg-blue-200" : ""
                    }`}
                  >
                    {_v}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="mt-4 flex flex-col justify-center items-center">
          <div className="heading text-center">
            {selectedLetters.map((v) => letters[questionNo][v.y][v.x])}
          </div>

          <button
            className={`heading ${
              selectedLetters.length > 0
                ? "bg-gray-600"
                : "bg-gray-200 cursor-default"
            }  text-white px-4 py-2 rounded mt-2 self-center`}
            disabled={selectedLetters.length === 0}
            onClick={() => {
              setSelectedLetters([]);
            }}
          >
            CLEAR
          </button>

          <div className="heading text-center mt-4">
            {answers[questionNo].map((v, i) => (
              <div key={i}>
                {v.map((_v, _i) => (
                  <span className="mx-2">
                    {`${i + 1}${_i === 0 ? "a" : "b"}. ${
                      _v.answered ? _v.answer : _v.hint
                    } `}
                  </span>
                ))}
              </div>
            ))}
          </div>
          {(finished || questionFinished) && (
            <>
              <div className="heading text-center text-2xl mt-4">
                PERFECT! YOU'RE DOING GREAT
              </div>
            </>
          )}

          {questionFinished ? (
            <button
              className={`heading bg-gray-600 text-white px-4 py-2 rounded mt-4 self-center`}
              onClick={() => {
                setQuestionNo(questionNo + 1);
                setQuestionFinished(false);
              }}
            >
              Continue to Next Stage
            </button>
          ) : (
            finished && (
              <button
                className={`heading bg-gray-600  text-white w-40 py-1 rounded mt-4`}
                onClick={() => {
                  history.replace("/games");
                }}
              >
                Back
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default WordSearch;
