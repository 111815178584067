import { images } from "../assets/images";

type car = {
  id: string;
  name: string;
  type: "sedan" | "suv";
  modelImage: any;
  interiorImages: { img: any; imgsmall: any; disclaimer?: string }[];
  interiorCaptions: string[];
  exteriorImages: { img: any; imgsmall: any; disclaimer?: string }[];
  exteriorCaptions: string[];
  videoUrl?: string;
  hasSubtype?: boolean;
  subtypeOf?: string;
};

export const cars: car[] = [
  {
    id: "nx",
    name: "NX",
    type: "suv",
    modelImage: images.nx_model,
    interiorImages: [
      { img: images.nx_int1, imgsmall: images.nx_int1s },
      { img: images.nx_int2, imgsmall: images.nx_int2s },
      { img: images.nx_int3, imgsmall: images.nx_int3s },
      { img: images.nx_int4, imgsmall: images.nx_int4s },
      { img: images.nx_int5, imgsmall: images.nx_int5s },
    ],
    interiorCaptions: [
      "The innovative nature of the NX is on full display inside the vehicle, including the evolution of Lexus' Human Machine Interface concept of separating the display and operation zones. The attention to detail Lexus is famous for has resulted in a premium feel where luxury seamlessly meets functionality.",
      "The class-leading distance between NX’s front and rear seats comes in impressive 962mm.",
      "The Remote Touch that eases the control of your display, the Lexus NX gives you a seamless experience in your every drive.",
      "A sensor underneath the rear bumper allows you to effortlessly open and close the rear door with a simple kicking motion.",
      "The parking assist monitor with the Panoramic 360-degree view of your camera accurately guides your parking attempts by superimposing projected parking lines on the back camera’s video feed. ",
    ],
    exteriorImages: [
      { img: images.nx_ext1, imgsmall: images.nx_ext1s },
      { img: images.nx_ext2, imgsmall: images.nx_ext2s },
      { img: images.nx_ext3, imgsmall: images.nx_ext3s },
      { img: images.nx_ext4, imgsmall: images.nx_ext4s },
      { img: images.nx_ext5, imgsmall: images.nx_ext5s },
    ],
    exteriorCaptions: [
      "A provocative and sport-inspired exterior design with performance to match. ",
      "The impressive design of the Lexus NX is accentuated by the sculpted character lines, which exceptionally sets you apart from the rest.",
      "The distinctive L-shaped lamps creates a wide signal and projecting a strong presence. The wide LED sequential turn signal lamps illuminate from the inner to the outer side of the lamp to intuitively convey the turn direction.",
      "Lexus NX’s grille is framed by sleek Bi-LED headlights and separate Daytime Running Lamps (DRLs), which make the vehicle highly visible to other vehicles and pedestrians.",
      "A sensor underneath the rear bumper allows you to effortlessly open and close the rear door with a simple kicking motion.",
    ],
  },
  {
    id: "rx",
    name: "RX",
    type: "suv",
    modelImage: images.rx_model,
    interiorImages: [
      { img: images.rx_int1, imgsmall: images.rx_int1s },
      { img: images.rx_int2, imgsmall: images.rx_int2s },
      { img: images.rx_int3, imgsmall: images.rx_int3s },
      { img: images.rx_int4, imgsmall: images.rx_int4s },
      { img: images.rx_int5, imgsmall: images.rx_int5s },
      { img: images.rx_int6, imgsmall: images.rx_int6s },
    ],
    interiorCaptions: [
      "Unparalleled craftsmanship and care in creating the finest interior quality - only in a Lexus.",
      "With innovations like Apple CarPlay compatibility and Android Auto functionality, the RX delivers connectivity that anticipates your every need. In addition, the RX now features an innovative touchscreen with pinch/zoom functionality.",
      "Smart Keyless Go",
      "The superintelligent 6-speed automatic transmission features advanced drive-force-on-demand control logic that realizes a high level of vehicle control in line with accelerator operation, together with a sophisticated acceleration feeling. ",
      "A subtle luxury touch is the design of the meters which fuses the simple functionality of 4-dial analog meters with ahi-tech digital display.The large analog speedometer and tachometer have three-dimensional meter faces for excellent visibility.",
      "The refined comfort of the seats is based on the spacious packaging that enables excellent seating posture and headroom, and a natural foot position for relaxed comfort. Second-row seat recline and slide functionsl et passengers tailor their position to further enhance ride comfort.",
    ],
    exteriorImages: [
      { img: images.rx_ext1, imgsmall: images.rx_ext1s },
      { img: images.rx_ext2, imgsmall: images.rx_ext2s },
      { img: images.rx_ext3, imgsmall: images.rx_ext3s },
      { img: images.rx_ext4, imgsmall: images.rx_ext4s },
      { img: images.rx_ext5, imgsmall: images.rx_ext5s },
    ],
    exteriorCaptions: [
      "A sculpted exterior that highlights a contemporary design that's bold, iconic, and timeless.",
      "The RX is framed by powerful LED headlights along with Daytime Running Lights (DRLs), which enhances the safety credentials of the vehicle by making it highly visible to other vehicles and pedestrians.",
      "A commanding grille with distinctive ultra-compact headlamps.",
      "Striking tail lamps wrap around the RX’s deeply carved side panels and light up in a glamourous display of brilliance.",
      "The Lexus RX's rear, the “seductive strength” theme can be seen in a new character line. The bottom edge of the rear bumper has been lowered and all the rear design elements have been set on a horizontal axis giving a stronger impression of width and more powerful stance.",
    ],
  },
  {
    id: "ux",
    name: "UX",
    type: "suv",
    modelImage: images.ux_model,
    interiorImages: [
      { img: images.ux_int1, imgsmall: images.ux_int1s },
      {
        img: images.ux_int2,
        imgsmall: images.ux_int2s,
      },
      { img: images.ux_int3, imgsmall: images.ux_int3s },
      { img: images.ux_int4, imgsmall: images.ux_int4s },
    ],
    interiorCaptions: [
      "Crafted by Lexus Takumi designers who've applied their impressive attention to detail throughout the interior - from the luxurious finishes through to advanced yet intuitive technology, making every journey a seamless experience.",
      "One-touch away to start your entertainment for your journey.",
      "The stitching of the smooth leather upholstery available in the UX is inspired by sashiko, a traditional Japanese quilting technique that dates back more than 1,200 years. Its strength, with up to ten stitches per inch, has long made it popular in the making of judo and kendo martial arts uniforms, and protective cotton coats worn by early Japanese fire fighters. ",
      "It requires a steady, skilled hand and is applied by Lexus takumi craftspeople. The quilted leather in the UX is decorated with new perforation patterns that form mathematical curves and gradations in perfect alignment.",
      "Taking inspiration from the LC tail lamps, the air vent knobs are wirelessly powered and intelligently illuminated to create a floating lighting depth effect.",
    ],
    exteriorImages: [
      { img: images.ux_ext1, imgsmall: images.ux_ext1s },
      {
        img: images.ux_ext2,
        imgsmall: images.ux_ext2s,
        disclaimer:
          "**Disclaimer: Overseas Lexus UX is shown. Brunei’s model specification and features may differ. See your Lexus dealer for more detail.",
      },
      { img: images.ux_ext3, imgsmall: images.ux_ext3s },
      { img: images.ux_ext4, imgsmall: images.ux_ext4s },
      { img: images.ux_ext5, imgsmall: images.ux_ext5s },
    ],
    exteriorCaptions: [
      "From the daring front end with its dramatic Lexus signature grille, to the sporty silhouette and bold rear design, drive around in style with the youthful and vibrant Lexus UX.",
      "Driving through narrower streets and tighter turns, demanding better handling and agility from you and your drive. Enter the Lexus UX. Built on the Global Architecture-C platform for a lower centre of gravity and more rigidity, experience exceptional linear stability and fast, stable cornering – without compromising on performance or comfort. ",
      "Bold, Dynamic and Stylish. The Lexus UX sets a statement wherever and whenever you drive it.",
      "Expressive design. The signature taillight of the Lexus UX is designed to be noticed.",
      "Light up the night with the Lexus UX. The all-LED tail lamps create a single continuous line of uniform light across the centre, giving UX an eye-catching point of intrigue.",
    ],
  },
  {
    id: "lx",
    name: "LX",
    type: "suv",
    videoUrl: "/videos/lx.mp4",
    modelImage: images.lx_model,
    interiorImages: [
      { img: images.lx_int1, imgsmall: images.lx_int1 },
      {
        img: images.lx_int2,
        imgsmall: images.lx_int2,
      },
      { img: images.lx_int3, imgsmall: images.lx_int3 },
      { img: images.lx_int4, imgsmall: images.lx_int4 },
      { img: images.lx_int5, imgsmall: images.lx_int5 },
    ],
    interiorCaptions: [
      "New for the LX, Lexus Interface connects you like never before. Among its abilities, available Intelligent Assistant integration lets you use everyday, casual speech to control most in-car features. Available cloud-based navigation offers more accurate directions and can provide real-time route information.",
      "Experience the epitome of luxury and refinement. Our first-ever four-seat LX indulges with massaging rear-seat captain’s chairs that recline up to 48 degrees. It features  world-class accommodations including a power-retractable ottoman and personal table.",
      "For the first time ever, the LX now features power 50/50-split third-row seats. This seating can be reclined for added comfort when in use, and folded flat into the cargo area when more space is needed. For faster, more convenient access, simply press the button in the cargo area to automatically fold or unfold the seats.",
      "The LX F Sport interior is trimmed in diamond-stitch semi-aniline leather, rich with beautiful leather accents.",
      "The LX series is built with seatings that can be reclined for added comfort when in use, and folded flat into the cargo area when more space is needed. For faster, more convenient access, simply press the button in the cargo area to automatically fold or unfold the seats.",
    ],
    exteriorImages: [
      { img: images.lx_ext1, imgsmall: images.lx_ext1 },
      {
        img: images.lx_ext2,
        imgsmall: images.lx_ext2,
      },
      { img: images.lx_ext3, imgsmall: images.lx_ext3 },
      { img: images.lx_ext4, imgsmall: images.lx_ext4 },
      { img: images.lx_ext5, imgsmall: images.lx_ext5 },
    ],
    exteriorCaptions: [
      "Completely reimagined for 2022, the all-new LX represents the next chapter of flagship luxury.",
      "Its exterior, a study in sophistication and strength, exhibits a silhouette that perfectly complements any road, on-road or off.",
      "With a suite of enhanced off-road technologies that include Active Height Control, Multi-Terrain Select, and Crawl Control,* the LX pushes the boundaries of capability.",
      "From low-light pedestrian detection and daytime bicyclist detection technology to a built-in camera that helps provide road sign information, this suite of class-leading standard active safety equipment* is the most comprehensive safety system ever included in the LX.",
      "Completely reimagined to elevate your driving experience to unprecedented heights, this is the next chapter of flagship luxury.",
    ],
  },
  {
    id: "is",
    name: "IS",
    type: "sedan",
    videoUrl: "https://www.youtube.com/watch?v=XzU2PiYhUAI",
    modelImage: images.is_model,
    interiorImages: [
      { img: images.is_int1, imgsmall: images.is_int1s },
      { img: images.is_int2, imgsmall: images.is_int2s },
      { img: images.is_int3, imgsmall: images.is_int3s },
      { img: images.is_int4, imgsmall: images.is_int4s },
      { img: images.is_int6, imgsmall: images.is_int6s },
      { img: images.is_int7, imgsmall: images.is_int7s },
      { img: images.is_int8, imgsmall: images.is_int8s },
    ],
    interiorCaptions: [
      "The IS interior is sporty at a glance but take a closer look and it is rich with beautiful leather accents and ornamentation inspired by traditional Japanese crafts. Each contrasting material works in harmony to create a uniquely memorable experience whether you're driving, or simply taking in the view.",
      "Intuitively designed and filled with the latest in-car technology, the IS should feel like an extension of you, a seamless connection to the road.",
      "The IS interior also includes 8-way power adjustable front seats, 10-speaker audio and dual-zone climate control, with each detail carefully considered to optimise accessibility and control. Such intelligent design creates a simpler and more connected driving experience.",
      "The IS interior is sporty at a glance, but take a closer look and it is rich with beautiful leather accents.",
      "Ornamentation in the Lexus IS is inspired by traditional Japanese crafts.",
      "BSM (Blind Spot Monitor System): When the quasi-millimetre-wave radars in the rear bumper detect vehicles in adjacent lanes that aren’t visible in the door mirrors, the system activates a warning indicator in the relevant door mirror the moment a vehicle enters this blind spot. (Exclusive only for IS 300 F SPORT)",
      "PKSB (Parking Support Brake): PKSB detects stationary objects such as walls in the direction of travel, as well as vehicles approaching from behind while reversing, and alerts the driver on the display and with an audible warning.",
    ],
    exteriorImages: [
      { img: images.is_ext1, imgsmall: images.is_ext1s },
      { img: images.is_ext2, imgsmall: images.is_ext2s },
      { img: images.is_ext3, imgsmall: images.is_ext3s },
      { img: images.is_ext4, imgsmall: images.is_ext4s },
      { img: images.is_ext5, imgsmall: images.is_ext5s },
    ],
    exteriorCaptions: [
      "The signature spindle grille seamlessly morphs with the silhouette of the IS, creating a bold impression that is sure to last.",
      "Built on a brand-new Lexus Driving Signature, the IS delivers distinctive performance, perfect for gentle cruising or fast driving — while providing a quieter, smoother experience that’s more enviable than ever.",
      "Creating provocative luxury for the discerning individual was the goal when crafting the new Lexus IS. The kind of agile refinement that catches the eye with just one look — and stirs the soul with just one drive.",
      'Adding sportiness to the new Lexus IS with a look and design that perfectly matched the wide base, 18" Aluminium Wheel, enhancing the driving experience that is unique to the Lexus Driving Signature.',
      "Innovative and intriguing, the all-LED tail lamps connect to create a single line of uniform light across the rear of the IS.",
    ],
  },
  {
    id: "es",
    name: "ES",
    type: "sedan",
    modelImage: images.es_model,
    hasSubtype: true,
    interiorImages: [],
    interiorCaptions: [],
    exteriorImages: [],
    exteriorCaptions: [],
  },
  {
    id: "es-f-sport",
    subtypeOf: "es",
    name: "ES F-SPORT",
    type: "sedan",
    modelImage: images.es_f_sport_model,
    interiorImages: [
      { img: images.es_f_sport_int1, imgsmall: images.es_f_sport_int1 },
      { img: images.es_f_sport_int2, imgsmall: images.es_f_sport_int2 },
      { img: images.es_f_sport_int3, imgsmall: images.es_f_sport_int3 },
      { img: images.es_f_sport_int4, imgsmall: images.es_f_sport_int4 },
      { img: images.es_f_sport_int5, imgsmall: images.es_f_sport_int5 },
      { img: images.es_f_sport_int6, imgsmall: images.es_f_sport_int6 },
      { img: images.es_f_sport_int7, imgsmall: images.es_f_sport_int7 },
    ],
    interiorCaptions: [
      "This feature automatically adjusts the front seats, steering wheel and outside rear view mirrors to make entering and exiting the vehicle easier or to suit your preferences.",
      "A direct evolution of the ground-breaking design and functionality of the meter in the Lexus LFA, the innovative single dial meter takes centre stage with a combination of Lexus sophistication and cutting-edge precision. In addition to showing information in a clear and logical manner, the meter ring display changes to reflect the drive mode selected.",
      "Available on ES 250 FSPORT, a 12.3-inch infotainment display with Apple CarPlay and Android Auto integration, and a 10-speaker Pioneer Premium audio system",
      "The front sports seats are the product of an innovative ‘integrated foaming’ manufacturing process. They provide a blend of refined comfort with firm support and excellent holding performance for sports driving. Available in Flared Red colour.",
      "For the F-SPORT, there are exclusive features to emphasize its sportier character, the classy light-emitting analogue clock on the dashboard has its own unique L-shaped graphics, while the meter has an electric movable metal ring with a 3-D effect.",
      "The sides of the cabin are lined with hadori aluminum material. This three-dimensional metallic texture is formed through the same technique used for forging Japanese swords.",
      "The ES 250 F SPORT elevates your comfort and convenience with more legroom, leather seats and power rear window sunshade.",
    ],
    exteriorImages: [
      { img: images.es_f_sport_ext1, imgsmall: images.es_f_sport_ext1 },
      { img: images.es_f_sport_ext2, imgsmall: images.es_f_sport_ext2 },
      { img: images.es_f_sport_ext3, imgsmall: images.es_f_sport_ext3 },
    ],
    exteriorCaptions: [
      'The New Lexus ES, a 2.5-liter in-line four-cylinder engine with refined luxuries are complemented by numerous expressions of modern style and technology—from a new, sport spindle grille to a new 19" rims design.',
      'The New Lexus ES, a 2.5-liter in-line four-cylinder engine with refined luxuries are complemented by numerous expressions of modern style and technology—from a new, sport spindle grille to a new 19" rims design.',
      'The New Lexus ES, a 2.5-liter in-line four-cylinder engine with refined luxuries are complemented by numerous expressions of modern style and technology—from a new, sport spindle grille to a new 19" rims design.',
    ],
  },
  {
    id: "es-250",
    subtypeOf: "es",
    name: "ES 250",
    type: "sedan",
    modelImage: images.es_250_model,
    interiorImages: [
      { img: images.es_250_int1, imgsmall: images.es_250_int1 },
      { img: images.es_250_int2, imgsmall: images.es_250_int2 },
      { img: images.es_250_int3, imgsmall: images.es_250_int3 },
      { img: images.es_250_int4, imgsmall: images.es_250_int4 },
    ],
    interiorCaptions: [
      "With its attention to detail, and innate sense of harmony, Lexus ES rewards at every turn and every touch. Sculpted 10-way power adjustable front heated and ventilated front seats hold you in firm comfort.",
      "Interior finishes, including wood trim add to the sense of handcrafted comfort.",
      "Available on ES 250, a 12.3-inch infotainment display with Apple CarPlay and Android Auto integration, and a 10-speaker Pioneer Premium audio system",
      "The ES 250 elevates your comfort and convenience with more legroom, leather seats and power rear window sunshade.",
    ],
    exteriorImages: [
      { img: images.es_250_ext1, imgsmall: images.es_250_ext1 },
      { img: images.es_250_ext2, imgsmall: images.es_250_ext2 },
      { img: images.es_250_ext3, imgsmall: images.es_250_ext3 },
    ],
    exteriorCaptions: [
      'The New Lexus ES, a 2.5-liter in-line four-cylinder engine with refined luxuries are complemented by numerous expressions of modern style and technology—from a new, sport spindle grille to a new 19" rims design.',
      'The New Lexus ES, a 2.5-liter in-line four-cylinder engine with refined luxuries are complemented by numerous expressions of modern style and technology—from a new, sport spindle grille to a new 19" rims design.',
      'The New Lexus ES, a 2.5-liter in-line four-cylinder engine with refined luxuries are complemented by numerous expressions of modern style and technology—from a new, sport spindle grille to a new 19" rims design.',
    ],
  },
  {
    id: "es-300h",
    subtypeOf: "es",
    name: "ES 300H",
    type: "sedan",
    modelImage: images.es_300h_model,
    interiorImages: [
      { img: images.es_300h_int1, imgsmall: images.es_300h_int1 },
      { img: images.es_300h_int2, imgsmall: images.es_300h_int2 },
      { img: images.es_300h_int3, imgsmall: images.es_300h_int3 },
      { img: images.es_300h_int4, imgsmall: images.es_300h_int4 },
      { img: images.es_300h_int5, imgsmall: images.es_300h_int5 },
    ],
    interiorCaptions: [
      "Choose a mode to suit the moment — from optimum efficiency to maximum exhilaration. The instrument cluster changes colour to reflect your mood, illuminating in blue for EV mode",
      "With its attention to detail, and innate sense of harmony, Lexus ES rewards at every turn and every touch. Sculpted 10-way power adjustable front heated and ventilated front seats hold you in firm comfort.",
      "Interior finishes, including wood trim add to the sense of handcrafted comfort.",
      "Available on ES 300h, a 12.3-inch infotainment display with Apple CarPlay and Android Auto integration, and a 10-speaker Pioneer Premium audio system",
      "The ES 300h elevates your comfort and convenience with more legroom, leather seats and power rear window sunshade.",
    ],
    exteriorImages: [
      { img: images.es_300h_ext1, imgsmall: images.es_300h_ext1 },
      { img: images.es_300h_ext2, imgsmall: images.es_300h_ext2 },
    ],
    exteriorCaptions: [
      "The Lexus ES 300h exterior is enhanced with 18” aluminum alloy wheels and sleek Spindle Grille design, giving elegance yet fierce vibe.",
      "The Lexus ES 300h exterior is enhanced with 18” aluminum alloy wheels and sleek Spindle Grille design, giving elegance yet fierce vibe.",
    ],
  },
];
