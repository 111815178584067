import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { images } from "../assets/images";
import { attachScript, detachScript } from "../components/TIQScript";

export const Trivia = () => {
  const history = useHistory();
  const questions: {
    image?: any;
    option: string[];
    title: string;
    subtitle?: string;
    question: string;
    answer: "a" | "b" | "c";
  }[] = [
    {
      title: "Lexus",
      subtitle: "Personality",
      option: [
        "A. Adventurous, Kind and Curious",
        "B. Brave, Thoughtful and Imaginative",
        "C. Ambitious, Generous and Artistic",
      ],
      answer: "b",
      question:
        "Lexus personality provides uniqueness and balance. Which of the following combination gives IS300 a well-rounded and balanced personality?",
    },
    {
      title: "Lexus",
      subtitle: "TAKUMI CRAFTMANSHIP",
      option: ["A. 600 hours", "B. 6,000 hours", "C. 60, 000 hours"],
      answer: "c",
      image: images.trivia1,
      question:
        "Takumi is the name given to Japanese master craftsmen. They devote a lifetime to their craft. Endless repetition, no shortcuts. How many hours does it take to be Lexus Takumi master craftsmen.",
    },
    {
      title: "Lexus",
      option: ["A. Omotenashi", "B. Ohayougozaimasu", "C. Onegaishimasu"],
      answer: "a",
      question:
        "It means anticipating and addressing needs before they arise. It is empathy in action: it is about an unsurpassed commitment and attention to our guests’ needs, a desire to ensure they enjoy an experience like no other.",
    },
    {
      title: "Lexus",
      subtitle: "ES",
      image: images.trivia2,
      option: ["A. 204 hp", "B. 300 hp", "C. 305 hp"],
      answer: "a",
      question: "What is the horsepower of LEXUS ES 250?",
    },
    {
      title: "Lexus",
      subtitle: "ES",
      option: [
        "A. Provocative design and the nimble handling of a hatchback. ",
        "B. Spaciousness and refinement with a stunning coupe-like silhouette.",
        "C. Inspiring, alluring and evocative.",
      ],
      answer: "b",
      question: "Lexus ES exterior is described as _______.",
    },
    {
      title: "Lexus",
      subtitle: "ES",
      image: images.trivia3,
      option: [],
      answer: "a",
      question: "Which of the following spindle grille belongs to Lexus ES?",
    },
    {
      title: "Lexus",
      subtitle: "NX/RX350 F-SPORT",
      image: images.trivia4,
      option: [
        "A. 360 degree view of your surrounding ",
        "B. Parking assist camera",
        "C. Blindspot monitor",
      ],
      answer: "a",
      question: "What is a Lexus Paranormic view camera?",
    },
    {
      title: "Lexus",
      subtitle: "F-SPORT",
      image: images.trivia5,
      option: ["A. F SPORT ", "B. Hybrid", "C. LFA"],
      answer: "a",
      question:
        "It ignites a passion for driving with intuitive technology and expressive design. An arsenal of performance-inspired features, packages and accessories make for a truly engaging automotive experience. This logo is the emblem of _______.",
    },
    {
      title: "Lexus",
      subtitle: "UX",
      image: images.trivia6,
      option: [
        "A. Ultimate Excitement",
        "B. Urban Explorer",
        "C. User Experience",
      ],
      answer: "b",
      question: "The “UX” name stands for ________.",
    },
    {
      title: "Lexus",
      subtitle: "UX",
      image: images.trivia7,
      option: [
        "A. Aero stabilising rear light",
        "B. Aerodynamic wheel arch mouldings",
        "C. All of the above",
      ],
      answer: "c",
      question:
        "Which of the following is part of Lexus UX’s aerodynamic design?",
    },
    {
      title: "Lexus",
      subtitle: "UX",
      option: ["A. 5.2m", "B. 8.2m", "C. 10.2m"],
      answer: "a",
      question:
        "Lexus UX is specifically designed for urban driving as it is compact and responsive. It has the best in class turning radius of ________ allows it to maneuver even the tightest spaces. ",
    },
    {
      title: "Lexus",
      subtitle: "RX",
      option: ["A. 1989", "B. 1998", "C. 2008"],
      answer: "b",
      question: "The Lexus RX’s first generation was introduced in _______. ",
    },
    {
      title: "Lexus",
      subtitle: "RX",
      image: images.trivia9,
      option: [],
      answer: "b",
      question:
        "Lexus RX 2020 introduced a new, more aggressive front end. The newly refreshed grille creates an elaborate look based on the “L-motif” logo. Which of the image below is the Lexus RX 2020 spindle grille?",
    },
    {
      title: "Lexus",
      subtitle: "RX",
      image: images.trivia10,
      option: [
        "A. 10 airbags, blind spot monitor and parking assist.",
        "B. 10 airbags, blind spot monitor, parking assist and LED cornering lights",
        "C. 10 airbags, blind spot monitor, parking assist and LED cornering lights and 360 paranormic view. ",
      ],
      answer: "b",
      question: "Which of the safety features are available in Lexus RX 300?",
    },
    {
      title: "Lexus",
      subtitle: "RX 300",
      image: images.trivia11,
      option: ["A. 6-speed", "B. 8-speed", "C. 10-speed"],
      answer: "a",
      question:
        "Lexus RX 300 has _______ automatic transmission that delivers an exciting drive by transmitting greater fuel economy with smooth shifting and powerful acceleration.",
    },
    {
      title: "Lexus",
      subtitle: "ES 250",
      option: ["A. 10", "B. 7", "C. 6"],
      answer: "b",
      question: "How many exterior colours are available for Lexus ES250?",
    },
    {
      title: "Lexus",
      subtitle: "NX",
      option: [
        "A. 2 litres engine, with twin-scroll turbocharging engine",
        "B. 2.5 litres",
        "C. 3.5 litres, V6 engine",
      ],
      answer: "a",
      question: "Which of the following is the engine of Lexus NX 300?",
    },
    {
      title: "Lexus",
      subtitle: "NX",
      image: images.trivia13,
      option: [],
      answer: "a",
      question: "Which of the following tire rims belong to Lexus NX?",
    },
    {
      title: "Lexus",
      subtitle: "NX",
      image: images.trivia14,
      option: [],
      answer: "a",
      question: "Which of the following LED headlight belong to Lexus NX?",
    },
  ];
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [answers, setAnswers] = React.useState<("a" | "b" | "c")[]>([]);
  const [currentAnswer, setCurrentAnswer] = React.useState<"a" | "b" | "c">();
  const [score, setScore] = React.useState(0);

  const [finished, setFinished] = React.useState(false);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: "Trivia",
      page_title: "Lexus Lexperience | Games",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (currentAnswer) {
      const _answers = [...answers, currentAnswer];
      setAnswers(_answers);
      if (currentQuestion + 1 < questions.length) {
        setCurrentAnswer(undefined);
        setCurrentQuestion(currentQuestion + 1);
      } else {
        let _score = 0;

        questions.forEach((v, i) => {
          if (v.answer === _answers[i]) {
            _score += 1;
          }
        });
        setScore(_score);
        setFinished(true);
      }
    }
  };

  return (
    <div className="max-w-screen-md mx-auto">
      <Helmet>
        <title>Lexus Lexperience | Trivia</title>
      </Helmet>

      <div className="heading text-center tracking-widest">T R I V I A</div>
      {!finished && (
        <div className="heading text-center tracking-widest">
          Question {currentQuestion + 1}/{questions.length}
        </div>
      )}
      <div className={`pt-6 md:pt-8 px-4 mb-12`}>
        <div className="w-full relative ">
          {finished ? (
            <div className="items-center justify-center flex flex-col heading">
              <div>Point</div>
              <div className="text-2xl">
                {score}/{questions.length}
              </div>
              <button
                className={`heading ${
                  currentAnswer ? "bg-gray-600" : "bg-gray-200 cursor-default"
                }  text-white w-40 py-1 rounded mt-4`}
                onClick={() => {
                  setAnswers([]);
                  setScore(0);
                  setFinished(false);
                  setCurrentQuestion(0);
                  setCurrentAnswer(undefined);
                }}
              >
                Try Again
              </button>
              <button
                className={`heading bg-gray-600  text-white w-40 py-1 rounded mt-4`}
                onClick={() => {
                  history.replace("/games");
                }}
              >
                Back
              </button>

              {/* Summary */}
              <div className="mt-8">
                <div className="heading text-xl text-center mb-12">Summary</div>
                {questions.map((v, i) => {
                  return (
                    <div key={`question-${i}`}>
                      <div className="py-4">
                        <div className="heading tracking-widest">
                          <span className="font-bold">{i + 1}. </span>
                          {v.title.toUpperCase()}
                          {v.subtitle
                            ? ` - ${v?.subtitle?.toUpperCase()}`
                            : ""}{" "}
                          -{" "}
                          {answers[i] === v.answer ? (
                            <span className="text-green-600 font-bold">
                              Correct
                            </span>
                          ) : (
                            <span className="text-red-600 font-bold">
                              Wrong
                            </span>
                          )}
                        </div>

                        <div className="heading">{v.question}</div>
                        {v.image && (
                          <img
                            src={v.image}
                            alt="car"
                            className="w-full object-contain mt-2"
                          />
                        )}

                        <div className="w-80 mt-4 mb-4">
                          {v.option.map((_v) => (
                            <div key={_v} className="heading ">
                              {_v}
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="heading">
                            Your answer:{" "}
                            <span className="font-bold">
                              {answers[i].toUpperCase()}
                            </span>
                          </div>
                          <div className="heading">
                            Correct answer:{" "}
                            <span className="font-bold">
                              {v.answer.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="h-px bg-gray-400 w-full my-4" />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="justify-center flex flex-col">
              <div className="heading text-xl tracking-widest">
                {questions[currentQuestion].title.toUpperCase()}
              </div>
              {questions[currentQuestion].subtitle && (
                <div className="heading text-xl tracking-widest">
                  {questions[currentQuestion]?.subtitle?.toUpperCase()}
                </div>
              )}
              <div className="w-0.5 h-10 my-1 bg-black" />
              <div className="heading">
                {questions[currentQuestion].question}
              </div>
              {questions[currentQuestion].image && (
                <img
                  src={questions[currentQuestion].image}
                  alt="car"
                  className="object-contain mt-2"
                />
              )}

              <div className="w-80 mt-8">
                {questions[currentQuestion].option.map((_v) => (
                  <div key={_v} className="heading ">
                    {_v}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-3 gap-12 mt-12 heading">
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="a"
                    checked={currentAnswer === "a"}
                    onChange={(e) => {
                      setCurrentAnswer("a");
                    }}
                  />
                  <span className="">A</span>
                </label>
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="b"
                    checked={currentAnswer === "b"}
                    onChange={(e) => {
                      setCurrentAnswer("b");
                    }}
                  />
                  <span className="">B</span>
                </label>
                <label className="flex flex-col items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 "
                    name="radio"
                    value="c"
                    checked={currentAnswer === "c"}
                    onChange={(e) => {
                      setCurrentAnswer("c");
                    }}
                  />
                  <span className="">C</span>
                </label>
              </div>
              <button
                className={`heading ${
                  currentAnswer ? "bg-gray-600" : "bg-gray-200 cursor-default"
                }  text-white px-4 py-2 rounded mt-12 self-center`}
                disabled={!currentAnswer}
                onClick={onSubmit}
              >
                SUBMIT
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Trivia;
