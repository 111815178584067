import React from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { attachScript, detachScript } from "../components/TIQScript";
import { cars } from "../data/cars";

export const CarList = () => {
  const param: { type: "sedan" | "suv"; subtype?: string } = useParams();

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: param.type,
      page_title: "Lexus Lexperience | Car List",
      product_name: "",
      product_price: "",
      product_series: "",
      product_type: "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col justify-center items-center heading pt-8 md:pt-12">
      <Helmet>
        <title>Lexus Lexperience | Car List</title>
      </Helmet>
      <div className="text-5xl text-center mb-12">
        {param.subtype ? param.subtype.toUpperCase() : param.type.toUpperCase()}
      </div>
      <div className="flex flex-col md:grid md:grid-cols-2 md:gap-16 justify-center items-center">
        {cars
          .filter(
            (v) =>
              v.type === param.type &&
              (param.subtype ? param.subtype === v.subtypeOf : !v.subtypeOf)
          )
          .map((v) => {
            return (
              <Link
                to={
                  v.hasSubtype
                    ? `/cars/${v.type}/${v.id}`
                    : `/detail/${v.id}/menu`
                }
                key={v.id}
                className="flex flex-col justify-center items-center mb-12"
              >
                <div className="text-3xl mb-2 text-center">{v.name}</div>

                <img
                  src={v.modelImage}
                  alt={v.id}
                  className="w-60 2xl:w-96 transition ease-in-out transform hover:scale-105 px-2"
                />
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default CarList;
