import React from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { attachScript, detachScript } from "../components/TIQScript";
import { cars } from "../data/cars";

export const Menu = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const car = cars.find((v) => v.id === id);

  if (car?.hasSubtype) {
    history.replace(`/cars/${car.type}/${car.id}`);
  }

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: car?.name ?? "",
      page_title: "Lexus Lexperience | Car Menu",
      product_name: `Lexus ${car?.name ?? ""}`,
      product_price: "",
      product_series: car?.name ?? "",
      product_type: car?.type ?? "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <Helmet>
        <title>Lexus Lexperience | {car?.name} Menu</title>
      </Helmet>
      <div className="heading text-center tracking-widest text-5xl mt-8 2xl:mt-12">
        {car?.name}
      </div>
      <div
        className={`heading pt-8 md:pt-12 lg:pt-16 grid md:flex md:justify-center md:items-center ${
          car?.videoUrl
            ? "grid-flow-row grid-cols-2"
            : "grid-flow-col grid-rows-2"
        }  gap-4 md:gap-16 `}
      >
        <div className="flex justify-center items-center">
          <Link
            to={`/detail/${id}/exterior`}
            className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
          >
            <div className="md:text-lg text-center ">EXTERIOR</div>
          </Link>
        </div>
        <div className="flex justify-center items-center">
          <Link
            to={`/detail/${id}/interior`}
            className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
          >
            <div className="md:text-lg text-center ">INTERIOR</div>
          </Link>
        </div>

        {car?.videoUrl && (
          <div className="flex justify-center items-center col-span-2">
            <Link
              to={`/detail/${id}/video`}
              className="flex justify-center items-center h-32 w-32 md:h-48 md:w-48 2xl:h-60 2xl:w-60 rounded-full bg-white border-gray-300 border-2 transform transition hover:scale-105"
            >
              <div className="md:text-lg text-center ">VIDEO</div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
