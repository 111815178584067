export const attachScript = (props: {
  page_subtitle: string;
  page_title: string;
  product_name: string;
  product_price: string;
  product_series: string;
  product_type: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const script = document.createElement("script");
  // const utag_data = {
  //   country_code: "bn",
  //   language_code: "en",
  //   page_category: "Standard",
  //   tealium_event: "microsite",
  //   product_url: window.location.href,
  //   ...props,
  // };
  // script.src = "https://tags.tiqcdn.com/utag/lexus/brunei/prod/utag.js";
  // script.async = true;
  // document.body.appendChild(script);

  return script;
};

export const detachScript = (props: { script: HTMLScriptElement }) => {
  // document.body.removeChild(props.script);
};
