import React from "react";
import { useParams } from "react-router-dom";
import LeadButton from "../components/LeadButton";
import { cars } from "../data/cars";
import { attachScript, detachScript } from "../components/TIQScript";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";

export const Video = () => {
  const { id }: { id: string; index: string } = useParams();

  const car = cars.find((v) => v.id === id);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: car?.name ?? "",
      page_title: "Lexus Lexperience | Video",
      product_name: `Lexus ${car?.name ?? ""}`,
      product_price: "",
      product_series: car?.name ?? "",
      product_type: car?.type ?? "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <title>Lexus Lexperience | {car?.name} Video</title>
      </Helmet>
      <div className="heading text-center tracking-widest">V I D E O</div>
      <div className={`pt-8 md:pt-12 `}>
        <div className="w-full relative flex justify-center items-center">
          <ReactPlayer url={car?.videoUrl} className={"w-full"} controls />
        </div>
      </div>
      <LeadButton />
    </div>
  );
};

export default Video;
