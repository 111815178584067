import React from "react";
import { images } from "../assets/images";
// import ReactGA from "react-ga4";

export const LeadButton = () => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-center heading mt-16 sm:mt-20 mb-20 sm:mb-8">
      <a
        href="https://www.lexus.com.bn/en/price-and-model-tools/model-brochures.html"
        target="_blank"
        rel="noreferrer"
        className="mb-4"
        // onClick={() => {
        //   ReactGA.event({
        //     action: "click_download_brochure",
        //     category: "cta",
        //     label: document.location.href,
        //   });
        // }}
      >
        <div className="flex items-center">
          <img src={images.download} alt="download" className="h-10 mr-4" />
          <div className="text-center">
            DOWNLOAD
            <br />
            BROCHURE
          </div>
        </div>
      </a>

      <div className="hidden sm:block w-px bg-gray-400 h-12 mx-6" />
      <a
        href="https://www.lexus.com.bn/en/contact-us/online-enquiry.html"
        target="_blank"
        rel="noreferrer"
        className="mb-4"
        // onClick={() => {
        //   ReactGA.event({
        //     action: "click_online_enquiry",
        //     category: "cta",
        //     label: document.location.href,
        //   });
        // }}
      >
        <div className="flex items-center">
          <img src={images.enquiry} alt="enquiry" className="h-10 mr-4" />
          <div className="text-center">
            ONLINE
            <br />
            ENQUIRY
          </div>
        </div>
      </a>
      <div className="hidden sm:block w-px bg-gray-400 h-12 mx-6" />
      <a
        href="https://www.lexus.com.bn/en/contact-us/book-a-test-drive.html"
        target="_blank"
        rel="noreferrer"
        className="mb-4"
        // onClick={() => {
        //   ReactGA.event({
        //     action: "click_online_enquiry",
        //     category: "cta",
        //     label: document.location.href,
        //   });
        // }}
      >
        <div className="flex items-center">
          <img src={images.test_drive} alt="test-drive" className="h-10 mr-4" />
          <div className="text-center">
            TEST
            <br />
            DRIVE
          </div>
        </div>
      </a>
    </div>
  );
};

export default LeadButton;
