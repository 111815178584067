import React from "react";

export const Footer = () => {
  return (
    <div className="absolute bottom-0 z-40 lg:z-50 w-full max-w-8xl mx-auto flex-none flex items-center justify-center p-4 ">
      <div className="tracking-widest text-sm text-center footer-text">
        © 2022{" "}
        <a
          href="https://inchcape.com.bn/"
          target={"_blank"}
          rel="noreferrer"
          className="cursor-pointer"
        >
          NBT SDN BHD
        </a>{" "}
        ALL RIGHTS RESERVED.{" "}
        <a href="https://tariot.agency" target={"_blank"} rel="noreferrer">
          POWERED BY TARIOT TECH AGENCY.
        </a>
      </div>
    </div>
  );
};

export default Footer;
