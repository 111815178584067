export const images = {
  background: require("./background.jpg").default,
  background_splash: require("./background_splash.jpg").default,
  background_splash_mobile: require("./background_splash_mobile.jpg").default,
  download: require("./download.png").default,
  enquiry: require("./enquiry.png").default,
  test_drive: require("./test_drive.webp").default,
  lexus: require("./lexus.png").default,
  lexus_white: require("./lexus_white.png").default,
  lexperience: require("./lexperience.png").default,
  lexperience_white: require("./lexperience_white.png").default,

  // difference game
  diff1: require("./difference/1.jpg").default,
  diffAnswer1: require("./difference/d1.jpg").default,
  diff2: require("./difference/2.jpg").default,
  diffAnswer2: require("./difference/d2.jpg").default,
  diff3: require("./difference/3.jpg").default,
  diffAnswer3: require("./difference/d3.jpg").default,
  diff4: require("./difference/4.jpg").default,
  diffAnswer4: require("./difference/d4.jpg").default,

  // trivia game
  trivia1: require("./trivia/1.jpg").default,
  trivia2: require("./trivia/2.png").default,
  trivia3: require("./trivia/3.png").default,
  trivia4: require("./trivia/4.png").default,
  trivia5: require("./trivia/5.png").default,
  trivia6: require("./trivia/6.png").default,
  trivia7: require("./trivia/7.jpg").default,
  trivia8: require("./trivia/8.png").default,
  trivia9: require("./trivia/9.png").default,
  trivia10: require("./trivia/10.png").default,
  trivia11: require("./trivia/11.png").default,
  trivia12: require("./trivia/12.png").default,
  trivia13: require("./trivia/13.png").default,
  trivia14: require("./trivia/14.png").default,

  // sedan IS
  is_model: require("./products/is300/is300.png").default,

  is_ext1: require("./products/is300/exterior/1.jpg").default,
  is_ext2: require("./products/is300/exterior/2.jpg").default,
  is_ext3: require("./products/is300/exterior/3.jpg").default,
  is_ext4: require("./products/is300/exterior/4.jpg").default,
  is_ext5: require("./products/is300/exterior/5.jpg").default,
  is_ext1s: require("./products/is300/exterior/1 small.jpg").default,
  is_ext2s: require("./products/is300/exterior/2 small.jpg").default,
  is_ext3s: require("./products/is300/exterior/3 small.jpg").default,
  is_ext4s: require("./products/is300/exterior/4 small.jpg").default,
  is_ext5s: require("./products/is300/exterior/5 small.jpg").default,

  is_int1: require("./products/is300/interior/1.jpg").default,
  is_int2: require("./products/is300/interior/2.jpg").default,
  is_int3: require("./products/is300/interior/3.jpg").default,
  is_int4: require("./products/is300/interior/4.jpg").default,
  is_int5: require("./products/is300/interior/5.jpg").default,
  is_int6: require("./products/is300/interior/6.jpg").default,
  is_int7: require("./products/is300/interior/7.jpg").default,
  is_int8: require("./products/is300/interior/8.jpg").default,
  is_int1s: require("./products/is300/interior/1 small.jpg").default,
  is_int2s: require("./products/is300/interior/2 small.jpg").default,
  is_int3s: require("./products/is300/interior/3 small.jpg").default,
  is_int4s: require("./products/is300/interior/4 small.jpg").default,
  is_int5s: require("./products/is300/interior/5 small.jpg").default,
  is_int6s: require("./products/is300/interior/6 small.jpg").default,
  is_int7s: require("./products/is300/interior/7 small.jpg").default,
  is_int8s: require("./products/is300/interior/8 small.jpg").default,

  // sedan ES
  es_model: require("./products/es/es.png").default,

  // ES F-Sport
  es_f_sport_model: require("./products/es/es-f-sport/es-f-sport.png").default,

  es_f_sport_ext1: require("./products/es/es-f-sport/exterior/1.jpg").default,
  es_f_sport_ext2: require("./products/es/es-f-sport/exterior/2.jpg").default,
  es_f_sport_ext3: require("./products/es/es-f-sport/exterior/3.png").default,

  es_f_sport_int1: require("./products/es/es-f-sport/interior/1.jpg").default,
  es_f_sport_int2: require("./products/es/es-f-sport/interior/2.jpg").default,
  es_f_sport_int3: require("./products/es/es-f-sport/interior/3.jpeg").default,
  es_f_sport_int4: require("./products/es/es-f-sport/interior/4.jpg").default,
  es_f_sport_int5: require("./products/es/es-f-sport/interior/5.jpg").default,
  es_f_sport_int6: require("./products/es/es-f-sport/interior/6.jpg").default,
  es_f_sport_int7: require("./products/es/es-f-sport/interior/7.jpg").default,

  // ES 300h
  es_300h_model: require("./products/es/es-300h/es-300h.png").default,

  es_300h_ext1: require("./products/es/es-300h/exterior/1.jpg").default,
  es_300h_ext2: require("./products/es/es-300h/exterior/2.jpg").default,

  es_300h_int1: require("./products/es/es-300h/interior/1.jpg").default,
  es_300h_int2: require("./products/es/es-300h/interior/2.jpg").default,
  es_300h_int3: require("./products/es/es-300h/interior/3.jpg").default,
  es_300h_int4: require("./products/es/es-300h/interior/4.jpeg").default,
  es_300h_int5: require("./products/es/es-300h/interior/5.jpg").default,

  // ES 250
  es_250_model: require("./products/es/es-250/es-250.png").default,

  es_250_ext1: require("./products/es/es-250/exterior/1.jpg").default,
  es_250_ext2: require("./products/es/es-250/exterior/2.jpg").default,
  es_250_ext3: require("./products/es/es-250/exterior/3.jpg").default,

  es_250_int1: require("./products/es/es-250/interior/1.jpg").default,
  es_250_int2: require("./products/es/es-250/interior/2.jpg").default,
  es_250_int3: require("./products/es/es-250/interior/3.jpeg").default,
  es_250_int4: require("./products/es/es-250/interior/4.jpg").default,

  // suv NX
  nx_model: require("./products/nx/nx.png").default,

  nx_ext1: require("./products/nx/exterior/1.jpg").default,
  nx_ext2: require("./products/nx/exterior/2.jpg").default,
  nx_ext3: require("./products/nx/exterior/3.jpg").default,
  nx_ext4: require("./products/nx/exterior/4.jpg").default,
  nx_ext5: require("./products/nx/exterior/5.jpg").default,
  nx_ext1s: require("./products/nx/exterior/1.jpg").default,
  nx_ext2s: require("./products/nx/exterior/2.jpg").default,
  nx_ext3s: require("./products/nx/exterior/3.jpg").default,
  nx_ext4s: require("./products/nx/exterior/4.jpg").default,
  nx_ext5s: require("./products/nx/exterior/5.jpg").default,

  nx_int1: require("./products/nx/interior/1.jpg").default,
  nx_int2: require("./products/nx/interior/2.jpg").default,
  nx_int3: require("./products/nx/interior/3.jpg").default,
  nx_int4: require("./products/nx/interior/4.jpg").default,
  nx_int5: require("./products/nx/interior/5.jpg").default,
  nx_int1s: require("./products/nx/interior/1.jpg").default,
  nx_int2s: require("./products/nx/interior/2.jpg").default,
  nx_int3s: require("./products/nx/interior/3.jpg").default,
  nx_int4s: require("./products/nx/interior/4.jpg").default,
  nx_int5s: require("./products/nx/interior/5.jpg").default,

  // suv RX
  rx_model: require("./products/rx/rx.png").default,

  rx_ext1: require("./products/rx/exterior/1.jpg").default,
  rx_ext2: require("./products/rx/exterior/2.jpg").default,
  rx_ext3: require("./products/rx/exterior/3.jpg").default,
  rx_ext4: require("./products/rx/exterior/4.jpg").default,
  rx_ext5: require("./products/rx/exterior/5.jpg").default,
  rx_ext1s: require("./products/rx/exterior/1 small.jpg").default,
  rx_ext2s: require("./products/rx/exterior/2 small.jpg").default,
  rx_ext3s: require("./products/rx/exterior/3 small.jpg").default,
  rx_ext4s: require("./products/rx/exterior/4 small.jpg").default,
  rx_ext5s: require("./products/rx/exterior/5 small.jpg").default,

  rx_int1: require("./products/rx/interior/1.jpg").default,
  rx_int2: require("./products/rx/interior/2.jpg").default,
  rx_int3: require("./products/rx/interior/3.jpg").default,
  rx_int4: require("./products/rx/interior/4.jpg").default,
  rx_int5: require("./products/rx/interior/5.jpg").default,
  rx_int6: require("./products/rx/interior/6.jpg").default,
  rx_int1s: require("./products/rx/interior/1 small.jpg").default,
  rx_int2s: require("./products/rx/interior/2 small.jpg").default,
  rx_int3s: require("./products/rx/interior/3 small.jpg").default,
  rx_int4s: require("./products/rx/interior/4 small.jpg").default,
  rx_int5s: require("./products/rx/interior/5 small.jpg").default,
  rx_int6s: require("./products/rx/interior/6 small.jpg").default,

  // suv UX
  ux_model: require("./products/ux/ux.png").default,

  ux_ext1: require("./products/ux/exterior/1.jpg").default,
  ux_ext2: require("./products/ux/exterior/2.jpg").default,
  ux_ext3: require("./products/ux/exterior/3.jpg").default,
  ux_ext4: require("./products/ux/exterior/4.jpg").default,
  ux_ext5: require("./products/ux/exterior/5.jpg").default,
  ux_ext1s: require("./products/ux/exterior/1 small.jpg").default,
  ux_ext2s: require("./products/ux/exterior/2 small.jpg").default,
  ux_ext3s: require("./products/ux/exterior/3 small.jpg").default,
  ux_ext4s: require("./products/ux/exterior/4 small.jpg").default,
  ux_ext5s: require("./products/ux/exterior/5 small.jpg").default,

  ux_int1: require("./products/ux/interior/1.jpg").default,
  ux_int2: require("./products/ux/interior/2.jpg").default,
  ux_int3: require("./products/ux/interior/3.jpg").default,
  ux_int4: require("./products/ux/interior/4.jpg").default,
  ux_int1s: require("./products/ux/interior/1 small.jpg").default,
  ux_int2s: require("./products/ux/interior/2 small.jpg").default,
  ux_int3s: require("./products/ux/interior/3 small.jpg").default,
  ux_int4s: require("./products/ux/interior/4 small.jpg").default,

  // suv LX
  lx_model: require("./products/lx/lx.png").default,
  lx_ext1: require("./products/lx/exterior/1.jpg").default,
  lx_ext2: require("./products/lx/exterior/2.jpg").default,
  lx_ext3: require("./products/lx/exterior/3.jpg").default,
  lx_ext4: require("./products/lx/exterior/4.jpg").default,
  lx_ext5: require("./products/lx/exterior/5.jpg").default,

  lx_int1: require("./products/lx/interior/1.jpg").default,
  lx_int2: require("./products/lx/interior/2.jpg").default,
  lx_int3: require("./products/lx/interior/3.jpg").default,
  lx_int4: require("./products/lx/interior/4.jpg").default,
  lx_int5: require("./products/lx/interior/5.jpg").default,
};
