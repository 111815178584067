import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useHistory, useParams } from "react-router-dom";
import LeadButton from "../components/LeadButton";
import { cars } from "../data/cars";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { attachScript, detachScript } from "../components/TIQScript";
import { Helmet } from "react-helmet";

export const InteriorDetail = () => {
  const history = useHistory();
  const { id, index }: { id: string; index: string } = useParams();

  const indexInt = parseInt(index);
  const car = cars.find((v) => v.id === id);

  React.useEffect(() => {
    const script = attachScript({
      page_subtitle: car?.name ?? "",
      page_title: "Lexus Lexperience | Interior Detail",
      product_name: `Lexus ${car?.name ?? ""}`,
      product_price: "",
      product_series: car?.name ?? "",
      product_type: car?.type ?? "",
    });

    return () => {
      detachScript({ script });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <title>Lexus Lexperience | Interior Detail</title>
      </Helmet>
      <div className="heading text-center tracking-widest">I N T E R I O R</div>
      <div className={`pt-8 md:pt-12 `}>
        <div className="w-full relative ">
          {indexInt > 0 && (
            <div
              onClick={() =>
                history.push(`/detail/${id}/interior/zoom/${indexInt - 1}`)
              }
              className="h-full flex justify-center items-center absolute left-0 p-4 cursor-pointer hover:bg-black hover:bg-opacity-5 transition z-10"
            >
              <i className="fas fa-chevron-left text-xl md:text-4xl text-white " />
            </div>
          )}
          {indexInt < (car?.interiorImages?.length ?? 5) - 1 && (
            <div
              onClick={() =>
                history.push(`/detail/${id}/interior/zoom/${indexInt + 1}`)
              }
              className="h-full flex justify-center items-center absolute right-0 p-4 cursor-pointer hover:bg-black hover:bg-opacity-5 transition z-10"
            >
              <i className="fas fa-chevron-right text-xl md:text-4xl text-white " />
            </div>
          )}
          <ProgressiveImage
            src={car?.interiorImages[indexInt || 0].img}
            placeholder={car?.interiorImages[indexInt || 0].imgsmall}
          >
            {(src: any, loading: boolean) => (
              <div className="h-full w-full relative">
                {loading && (
                  <div className="absolute z-40 inset-0 bg-black bg-opacity-20 flex justify-center items-center">
                    <Lottie animationData={loadingAnimation} />
                  </div>
                )}
                <img
                  src={src}
                  alt={car?.name}
                  className="h-full w-full object-cover"
                />
              </div>
            )}
          </ProgressiveImage>
        </div>
        <p className="text-center mt-8 heading mx-8">
          {car?.interiorCaptions[indexInt || 0]}
        </p>
      </div>

      <LeadButton />
    </div>
  );
};

export default InteriorDetail;
