/* eslint-disable no-useless-escape */
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../api";
import ClipLoader from "react-spinners/ClipLoader";

export const Form = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [formValue, setFormValue] = React.useState<{
    name: string;
    mobileNo: string;
    email: string;
    ICNumber: string;
  }>({
    name: "",
    mobileNo: "",
    ICNumber: "",
    email: "",
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    window.history.replaceState(null, "", `${location.pathname}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSend = () => {
    if (
      !formValue.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      alert("Email invalid");
      return;
    }

    setLoading(true);

    API.postForm({ ...formValue, game: query.get("game") ?? "" })
      .then(() => {
        setLoading(false);
        setFormValue({ name: "", mobileNo: "", ICNumber: "", email: "" });
        alert("Form succesfully sent!");
        history.replace("/games");
      })
      .catch(() => {
        setLoading(false);
        alert("Error sending form");
      });
  };

  return (
    <div className="max-w-lg mx-auto">
      <Helmet>
        <title>Lexus Lexperience | Form</title>
      </Helmet>

      <div className="heading text-center tracking-widest">
        {(query.get("game") === "difference"
          ? "spot the difference"
          : query.get("game") === "wordsearch"
          ? "word search"
          : query.get("game")
        )
          ?.toUpperCase()
          .replaceAll(" ", `\xa0`)
          .split("")
          .join(" ")}
      </div>

      <div className={`pt-6 md:pt-8 px-4 mb-12`}>
        <div className="w-full relative ">
          <h3 className="text-xl font-bold leading-6 text-gray-900 text-center mt-8 mb-6">
            THANK YOU FOR PLAYING OUR MINI GAMES!
          </h3>
          <p className="heading text-center mb-8">
            Fill in the following details to participate in our giveaway:
          </p>
          <input
            value={formValue.name}
            onChange={(e) =>
              setFormValue({ ...formValue, name: e.target.value })
            }
            type="text"
            className="focus:ring-0 focus:outline-none block w-full py-2 px-8 rounded-md heading tracking-wider border border-gray-400 mb-6"
            placeholder="Name (as per Identity Card)"
          />
          <div className="relative">
            <input
              value={formValue.mobileNo}
              onChange={(e) =>
                setFormValue({
                  ...formValue,
                  mobileNo: e.target.value.replace(/\D/g, ""),
                })
              }
              type="text"
              className={`focus:ring-0 focus:outline-none block w-full py-2 pr-8 ${
                formValue.mobileNo ? "pl-12" : "pl-8"
              } rounded-md heading tracking-wider border border-gray-400 mb-6`}
              placeholder="Mobile No. (+673 XXXXXXX)"
            />
            {formValue.mobileNo ? (
              <p className="absolute left-8 inset-y-2">+</p>
            ) : null}
          </div>

          <input
            value={formValue.email}
            onChange={(e) =>
              setFormValue({ ...formValue, email: e.target.value })
            }
            type="text"
            className="focus:ring-0 focus:outline-none block w-full py-2 px-8 rounded-md heading tracking-wider border border-gray-400 mb-6"
            placeholder="Email Address"
          />
          <input
            value={formValue.ICNumber}
            onChange={(e) =>
              setFormValue({
                ...formValue,
                ICNumber: e.target.value.replace(/\D/g, ""),
              })
            }
            type="text"
            className="focus:ring-0 focus:outline-none block w-full py-2 px-8 rounded-md heading tracking-wider border border-gray-400 mb-6"
            placeholder="IC Number"
          />
          <div className="flex justify-center items-center ">
            {!loading ? (
              <button
                className={`heading bg-gray-600 text-white w-40 py-2 rounded mt-4`}
                onClick={onSend}
              >
                SUBMIT <i className="ml-2 fas fa-chevron-right" />
              </button>
            ) : (
              <ClipLoader color="#444" loading={loading} size={24} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
