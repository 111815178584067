import React from "react";
import { BrowserRouter, Route, Redirect, useLocation } from "react-router-dom";
import { images } from "./assets/images";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { AnimatedSwitch } from "react-router-transition";
import ClipLoader from "react-spinners/ClipLoader";
import Countdown from "react-countdown";

// pages
import Home from "./pages/Home";
import CarList from "./pages/CarList";
import Menu from "./pages/Menu";
import Exterior from "./pages/Exterior";
import Interior from "./pages/Interior";
import ExteriorDetail from "./pages/ExteriorDetail";
import InteriorDetail from "./pages/InteriorDetail";
import Video from "./pages/Video";
import GamesMenu from "./pages/GamesMenu";
import Puzzle from "./pages/Puzzle";
import Difference from "./pages/Difference";
import Trivia from "./pages/Trivia";
import WordSearch from "./pages/WordSearch";
import Form from "./pages/Form";
import { SplashContext } from "./data/context";
import { API } from "./api";
import moment from "moment";

declare global {
  interface Error {
    response: {
      data: {
        message: string;
      };
    };
  }
}

const Navigation = () => {
  const location = useLocation();

  return (
    <div
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + location.pathname === "/home"
            ? "/images/background-home.jpg"
            : "/images/background.jpg"
        })`,
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="min-h-screen relative z-10 mx-auto pb-12 flex flex-col items-center ">
        <Navbar />
        <img
          src={images.lexperience}
          alt="lexus_logo"
          className="h-16 md:h-20"
        />
        <div className="overflow-x-hidden max-w-screen-lg xl:max-w-screen-xl pb-6 w-full">
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 2 }}
            atActive={{ opacity: 1 }}
            mapStyles={(styles) => {
              if (styles.opacity > 1) {
                return { display: "none" };
              }
              return { opacity: styles.opacity };
            }}
            className="switch-wrapper"
          >
            <Route path="/home" exact render={() => <Home />} />
            <Route path="/detail/:id/menu" exact render={() => <Menu />} />

            <Route path="/games" exact render={() => <GamesMenu />} />
            <Route path="/games/puzzle" exact render={() => <Puzzle />} />
            <Route path="/games/words" exact render={() => <WordSearch />} />
            <Route
              path="/games/difference"
              exact
              render={() => <Difference />}
            />
            <Route path="/games/form" exact render={() => <Form />} />
            <Route path="/games/trivia" exact render={() => <Trivia />} />
            <Route
              path="/detail/:id/exterior"
              exact
              render={() => <Exterior />}
            />
            <Route
              path="/detail/:id/exterior/zoom/:index"
              exact
              render={() => <ExteriorDetail />}
            />
            <Route
              path="/detail/:id/interior"
              exact
              render={() => <Interior />}
            />
            <Route
              path="/detail/:id/interior/zoom/:index"
              exact
              render={() => <InteriorDetail />}
            />
            <Route path="/detail/:id/video" exact render={() => <Video />} />

            <Route path="/cars/:type" exact render={() => <CarList />} />
            <Route
              path="/cars/:type/:subtype"
              exact
              render={() => <CarList />}
            />
            <Redirect to="/home" />
          </AnimatedSwitch>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const App = () => {
  const [showSplash, setShowSplash] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showCountdown, setShowCountdown] = React.useState(true);
  const [countdownEndTime, setCountdownEndTime] = React.useState();
  const [serverTime, setServerTime] = React.useState<number>();
  const countdownRef = React.useRef<Countdown>(null);

  React.useEffect(() => {
    API.getTime().then((time) => {
      API.getUtils().then((res) => {
        const countdownEndTime = res.find(
          (v) => v.id === "countdown_end_time"
        )?.value;

        setShowCountdown(moment(time).isBefore(countdownEndTime));
        setCountdownEndTime(countdownEndTime);
        setServerTime(time);
        setLoading(false);
      });
    });
  }, []);

  React.useEffect(() => {
    if (countdownEndTime && serverTime) {
      countdownRef.current?.start();
    }
  }, [countdownEndTime, serverTime]);

  return (
    <SplashContext.Provider value={showSplash}>
      {loading ? (
        <div className="min-h-screen min-w-screen flex justify-center items-center ">
          <ClipLoader color="#aaa" loading={true} size={32} />
        </div>
      ) : showCountdown ? (
        <div className="bg-black min-h-screen">
          <div
            className={`flex flex-col max-w-screen-lg mx-auto items-center min-h-screen overflow-hidden`}
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/images/background_countdown.png"
              })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className=" bg-black p-12 pb-0 w-full mb-0">
              <img
                src={images.lexus_white}
                alt="lexus_logo"
                className="h-8 lg:h-10 2xl:h-12 mb-8 mx-auto"
              />
              <img
                src={images.lexperience_white}
                alt="lexus_logo"
                className="h-12 lg:h-16 2xl:h-24 mx-auto"
              />
            </div>
            <div className="bg-gradient-to-b from-black to-transparent flex flex-col w-full pt-4 justify-center items-center flex-1">
              <Countdown
                date={Date.now() + moment(countdownEndTime).valueOf()}
                now={() => {
                  return Date.now() + moment(serverTime).valueOf();
                }}
                renderer={({ days }) => {
                  return (
                    <div>
                      <div
                        className="text-white heading 2xl:hidden"
                        style={{ fontSize: 160, lineHeight: 1 }}
                      >
                        {days}
                      </div>
                      <div
                        className="text-white heading hidden 2xl:block"
                        style={{ fontSize: 240, lineHeight: 1 }}
                      >
                        {days}
                      </div>
                    </div>
                  );
                }}
              />
              <div className="text-white heading text-4xl 2xl:text-6xl tracking-widest mt-2 mb-8">
                Days
              </div>
            </div>
            <div className="bg-gradient-to-t from-black to-transparent flex flex-col items-center w-full flex-1">
              <div className="text-white heading lg:text-xl 2xl:text-2xl font-extrabold tracking-widest mb-4">
                JOURNEY TIMELESS IN MOTION
              </div>
              <Countdown
                ref={countdownRef}
                date={Date.now() + moment(countdownEndTime).valueOf()}
                now={() => {
                  return Date.now() + moment(serverTime).valueOf();
                }}
                autoStart
                renderer={({ days, hours, minutes, seconds, completed }) => {
                  if (completed) {
                    setShowCountdown(false);
                  }
                  return (
                    <div className="flex space-x-6">
                      <div>
                        <div className="bg-white h-20 w-20 heading flex items-center justify-center text-5xl">
                          {days}
                        </div>
                        <div className="mt-2 heading font-extrabold lg:text-xl 2xl:text-2xl text-center text-white tracking-widest">
                          Days
                        </div>
                      </div>
                      <div>
                        <div className="bg-white h-20 w-20 heading flex items-center justify-center text-5xl">
                          {hours}
                        </div>
                        <div className="mt-2 heading font-extrabold lg:text-xl 2xl:text-2xl text-center text-white tracking-widest">
                          Hrs
                        </div>
                      </div>
                      <div>
                        <div className="bg-white h-20 w-20 heading flex items-center justify-center text-5xl">
                          {minutes}
                        </div>
                        <div className="mt-2 heading font-extrabold lg:text-xl 2xl:text-2xl text-center text-white tracking-widest">
                          Mins
                        </div>
                      </div>
                      <div>
                        <div className="bg-white h-20 w-20 heading flex items-center justify-center text-5xl">
                          {seconds}
                        </div>
                        <div className="mt-2 heading font-extrabold lg:text-xl 2xl:text-2xl text-center text-white tracking-widest">
                          Secs
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
              <div className="flex-1" />
              <div className="tracking-widest text-sm text-center footer-text text-white mt-8 mb-4">
                © 2022{" "}
                <a
                  href="https://inchcape.com.bn/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  NBT SDN BHD
                </a>{" "}
                ALL RIGHTS RESERVED.{" "}
                <a
                  href="https://tariot.agency"
                  target={"_blank"}
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  POWERED BY TARIOT TECH AGENCY.
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${showSplash ? "max-h-screen overflow-hidden" : ""}`}>
          <BrowserRouter>
            <div
              onClick={() => {
                setShowSplash(false);
              }}
              className={`cursor-pointer hidden md:flex md:flex-col items-start absolute inset-0 z-50 p-12 transform transition ease-in-out duration-500 bg-gray-100 ${
                !showSplash && "-translate-y-full"
              }`}
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/images/background_splash.jpg"
                })`,
                backgroundPosition: "bottom",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img
                src={images.lexus}
                alt="lexus_logo"
                className="h-16 2xl:h-24"
              />
              <div className="flex-1" />

              <div className="heading text-2xl 2xl:text-4xl">Welcome to</div>
              <img
                src={images.lexperience}
                alt="lexus_logo"
                className="h-20 2xl:h-32"
              />
              <div className="heading mb-20 2xl:text-xl">Click to continue</div>

              <div className="tracking-widest text-sm text-center footer-text">
                © 2022{" "}
                <a
                  href="https://inchcape.com.bn/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  NBT SDN BHD
                </a>{" "}
                ALL RIGHTS RESERVED.{" "}
                <a
                  href="https://tariot.agency"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  POWERED BY TARIOT TECH AGENCY.
                </a>
              </div>
            </div>
            <div
              onClick={() => {
                setShowSplash(false);
              }}
              className={`cursor-pointer flex flex-col md:hidden items-center absolute inset-0 z-50 p-4 transform transition ease-in-out duration-500 bg-gray-100 ${
                !showSplash && "-translate-y-full"
              }`}
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL +
                  "/images/background_splash_mobile.jpg"
                })`,
                backgroundPosition: "bottom",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: `100vh`,
              }}
            >
              <img src={images.lexus} alt="lexus_logo" className="h-16 " />
              <div className="flex-1" />

              <div className="heading text-2xl">Welcome to</div>
              <img src={images.lexperience} alt="lexus_logo" className="h-20" />
              <div className="heading mb-20">Touch to continue</div>

              <div className="tracking-widest text-sm text-center footer-text">
                © 2022{" "}
                <a
                  href="https://inchcape.com.bn/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  NBT SDN BHD
                </a>{" "}
                ALL RIGHTS RESERVED.{" "}
                <a
                  href="https://tariot.agency"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  POWERED BY TARIOT TECH AGENCY.
                </a>
              </div>
            </div>
            <Navigation />
          </BrowserRouter>
        </div>
      )}
    </SplashContext.Provider>
  );
};

export default App;
